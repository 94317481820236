import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useContext } from "react";
import "./index.scss";
import { Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import { FaHome } from "react-icons/fa";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TabsSkillsInterviews from "../../components/TabsSkillsInterviews/TabsSkillsInterviews";
import usersService from "../../services/UsersService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImagePicker } from "../../components/ImagePicker";
import AuthContext from "../../context/auth";
import { useNavigate } from "react-router-dom";

// interface ISkills {
//   id: number;
//   name: string;
//   description: string;
//   type: string;
//   total: number;
// }

interface IUser {
  id: number;
  name: string;
  email: string;
  password: string;
  active: string;
  role: string;
  created_at: Date;
  profileImage: string | null;
  student: {
    id: number;
    created_at: Date;
    helping_points: number;
    linkedin: string | null;
    city: string | null;
    state: string | null;
    youtube_address: string | null;
  } | null;
}

function ProfileStudent() {
  const { markAsNotSigned, reloadLocalStorage } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dataUser, setDataUser] = useState<IUser>({} as IUser);
  const [editable, setEditable] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [urlPhotoProfile, setUrlPhotoProfile] = useState<string | null>(null);
  const [removePhotoProfile, setRemovePhotoProfile] = useState<boolean>(false);
  const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false); // Novo estado para controlar o botão "Enviar"

  const removePhoto = async () => {
    await usersService.removePhotoProfile().finally(() => {
      getDataUser();
      setUrlPhotoProfile(null);
      setIsSendDisabled(false); // Reabilita o botão "Enviar" após a remoção da imagem
      saveDataProfile();
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      linkedin: "",
      youtube: "",
      password: "",
      confPassword: "",
    },

    validationSchema: Yup.object({
      password: Yup.string().min(6, "Senha deve ter no mínimo 6 caracteres"),
      confPassword: Yup.string().oneOf(
        [Yup.ref("password")],
        "Senha devem ser iguais"
      ),
    }),

    onSubmit: async (values) => {
      let data = {
        name: values.name,
        email: values.email,
        password: values.password,
        student: {
          linkedin: values.linkedin,
          youtubeAdress: values.youtube,
        },
        profileImage: removePhotoProfile ? null : dataUser.profileImage,
      };

      try {
        const response = await usersService.updateDataProfile(data);
        if (response.status) {
          if (values.email !== dataUser.email) {
            logout();
          }

          getDataUser();
          setRemovePhotoProfile(false);
        }
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: error.message, // Exibe a mensagem de erro recebida do backend
          confirmButtonText: "Ok",
        });
      }
    },
  });

  const logout = () => {
    localStorage.removeItem("TOKEN");
    markAsNotSigned();
    navigate("/");
  };

  const getDataUser = async () => {
    await usersService.getByToken().then((response) => {
      let dataUser: IUser = response.data;
      formik.values.name = dataUser.name;
      formik.values.email = dataUser.email;
      formik.values.linkedin = dataUser.student?.linkedin
        ? dataUser.student?.linkedin
        : "";
      formik.values.youtube = dataUser.student?.youtube_address
        ? dataUser.student?.youtube_address
        : "";

      if (dataUser.profileImage != null && dataUser.profileImage != "") {
        setUrlPhotoProfile(
          `https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${dataUser.profileImage}`
        );
      }

      let user = {
        name: dataUser.name,
        photoProfile: dataUser.profileImage,
        role: dataUser.role,
      };
      localStorage.removeItem("USER");
      localStorage.setItem("USER", JSON.stringify(user));

      setDataUser(dataUser);
    });
  };

  const handleFileChange = async (file: File | null) => {
    setSelectedFile(file);
    if (file) {
      setIsSendDisabled(true); // Desabilita o botão "Enviar" após o upload da imagem
      const response = await usersService.uploadPhotoProfile(file);
      if (response.status) {
        console.log("Imagem carregada com sucesso!");
      } else {
        console.error("Erro ao carregar a imagem:", response.message);
      }
      getDataUser(); // Recarrega os dados do usuário para atualizar a imagem do perfil
      saveDataProfile();
    }
  };

  const handleEditButton = () => {
    setEditable(true);
  };

  const saveDataProfile = () => {
    Swal.fire({
      icon: "success",
      title: "Parabéns!",
      text: "Dados salvos com sucesso!",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        setEditable(false);
        reloadLocalStorage();
      }
    });
  };

  useEffect(() => {
    getDataUser();
  }, []);

  return (
    <Container>
      <nav className="breadcrumb-talent">
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>

          <li>
            <a className="p_normal" href="">
              Meu perfil
            </a>
          </li>
        </ol>
      </nav>

      <div className="App">
        <div className="space"></div>
        <h2 className="title">Meu Perfil</h2>
        <div className="space"></div>

        <Grid className="container-profile">
          <div className="d-flex justify-content-center mt-4 mb-3 custom-image">
            <ImagePicker
              handleFileChange={handleFileChange}
              initialImage={urlPhotoProfile}
              accept="image/png, image/jpeg, image/jpg"
            />{" "}
            {/* Adicionado o accept aqui */}
          </div>

          <form
            className="form-dados"
            style={{ gap: "28px" }}
            onSubmit={formik.handleSubmit}
          >
            <div className="space"></div>

            <h2 className="title">Dados Pessoais</h2>
            <span className="title-left bold label-dados">Nome completo</span>
            <Form.Group
              id="name"
              className="mb-3 lg"
              controlId="complete-name"
            >
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!editable}
                required
              />
            </Form.Group>
            {formik.touched.name && formik.errors.name ? (
              <div className="error_msg">{formik.errors.name}</div>
            ) : null}

            <div className="space"></div>

            <span className="title-left bold label-dados">E-mail</span>
            <Form.Group
              id="email"
              className="mb-3 lg"
              controlId="user-email"
            >
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!editable}
                required
              />
            </Form.Group>
            {formik.touched.email && formik.errors.email ? (
              <div className="error_msg">{formik.errors.email}</div>
            ) : null}

            <div className="space"></div>

            <span className="title-left bold label-dados">Perfil LinkedIn</span>
            <Form.Group
              id="linkedin"
              className="mb-3 lg"
              controlId="linkedin-profile"
            >
              <Form.Control
                type="text"
                name="linkedin"
                value={formik.values.linkedin}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!editable}
              />
            </Form.Group>
            {formik.touched.linkedin && formik.errors.linkedin ? (
              <div className="error_msg">{formik.errors.linkedin}</div>
            ) : null}

            <div className="space"></div>

            <span className="title-left label-dados">
              <b>Video Youtube&nbsp;</b> (Grave um vídeo de apresentação de
              até&nbsp;<b>30 segundos</b>, envie no Youtube e&nbsp;
              <b>cole aqui</b>&nbsp;o link do vídeo)
            </span>
            <Form.Group className="mb-3 lg" controlId="youtube-video">
              <Form.Control
                type="text"
                name="youtube"
                value={formik.values.youtube}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!editable}
              />
            </Form.Group>
            {formik.touched.youtube && formik.errors.youtube ? (
              <div className="error_msg">{formik.errors.youtube}</div>
            ) : null}

            <div className="space"></div>

            <h2 className="title">Senha</h2>
            <div className="mlinputcontainer">
              <Row>
                <Col>
                  <span className="title-left bold label-dados">
                    Senha (Opcional)
                  </span>
                  <Form.Group className="mb-3" controlId="basic-password">
                    <Form.Control
                      type="password"
                      placeholder="••••••"
                      name="password"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      disabled={!editable}
                    />
                  </Form.Group>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error_msg">{formik.errors.password}</div>
                  ) : null}
                </Col>

                <Col>
                  <span className="title-left bold label-dados">
                    Confirme sua senha
                  </span>
                  <Form.Group className="mb-3" controlId="confirm-password">
                    <Form.Control
                      type="password"
                      placeholder="••••••"
                      name="confPassword"
                      value={formik.values.confPassword}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      disabled={!editable}
                    />
                    {formik.touched.confPassword &&
                    formik.errors.confPassword ? (
                      <div className="error_msg">
                        {formik.errors.confPassword}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="space"></div>

            <Container>
              {!editable ? (
                <button
                  className="btn_primary"
                  id="button-edit-profile"
                  type="button"
                  onClick={() => {
                    handleEditButton();
                  }}
                >
                  Editar
                </button>
              ) : (
                <button
                  className="btn_primary"
                  id="buttonEditar"
                  type="submit"
                  onClick={() => {
                    saveDataProfile();
                  }}
                  disabled={isSendDisabled}
                >
                  Salvar
                </button>
              )}
            </Container>
          </form>
        </Grid>

        <div className="space"></div>

        <TabsSkillsInterviews />
      </div>
    </Container>
  );
}

export default ProfileStudent;
